
@import url('https://fonts.googleapis.com/css2?family=Montserrat&family=Poppins:wght@700&family=Public+Sans:wght@400;700&family=Tilt+Prism&display=swap');



/* Common */
.privacy-screen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: black;
  z-index: 99;
}

body {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

h2 {
  font-weight: 400;
}

.portfolio-container section,
section.home-container,
section.profile-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  scroll-snap-align: center;
}

/* Mobile menu */

.mobile-menu-btn {
  position: absolute;
  left: 0;
  width: 100vw;
  height: 100vh;
}

nav.menu-nav {
  position: absolute;
  top: 0;
  right: 0;
  width: 100vw;
  z-index: 99;
}

.menu-nav ul {
  visibility: hidden;
}

.menu-nav.active {
  bottom: 0;
}

.menu-nav.active ul {
  visibility: visible;
}

.menu-nav .background {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100vw;
  background: #00000099;
  backdrop-filter: blur(10px);
}

.menu-nav button {
  outline: none;
  border: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  cursor: pointer;
  position: absolute;
  top: 18px;
  right: 15px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: transparent;
}

.menu-nav ul,
.menu-nav li {
  margin: 0;
  padding: 0;
}

.menu-nav ul {
  padding: 25px;
  position: absolute;
  top: 200px;
  left: calc((100vw - 230px) / 2);
  width: 230px;
  font-size: 50px;
}

.menu-nav li {
  list-style: none;
  margin-bottom: 50px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.menu-nav .text-placeholder {
  border-radius: 5px;
  width: 200px;
  height: 20px;
  flex: 1;
}

/* Portfolio */
div.project-content {
  margin: 0;
  color: var(--accent);
  right: 1vw;
  z-index: 2;
  width: min(400px, 90vw);
  height: 300px;
  background: white;
  padding-left: 5px;
  border-radius: 14px;
  box-shadow: black 10px 10px;
  padding: 10px;
  margin-bottom: 30px;
}

div.project-bg-circle {
  width: 150px;
  height: 150px;
  background: blue;
  position: absolute;
  border-radius: 200px;
  bottom: 100px;
  left: 200px;
}

div.project-bg-circle-small {
  width: 100px;
  height: 100px;
  border: solid 5px black;
  position: absolute;
  top: 100px;
  right: 200px;
  rotate: 45deg;
}

div.project-bg-square {
  width: min(600px, 100vw);
  height: 400px;
  border: solid 5px darkblue;
  position: absolute;
  rotate: -8deg;
  border-radius: 10px;
}

div.project-bg-square.vertical {
  width: min(400px, 100vw);
  height: 600px;
  rotate: 8deg;
}

div.other-project-content {
  color: var(--accent);
  width: min(600px, 90vw);
  background: white;
  border-radius: 14px;
  box-shadow: black 10px 10px;
  padding: 10px;
}

.project-content h3,
.other-project-content h3 {
  font-size: 40px;
  font-weight: 700;
  letter-spacing: -3px;
  line-height: 1.2;
}

.project-content h3 {
  font-size: 40px;
  font-weight: 700;
  letter-spacing: -3px;
  line-height: 1.2;
}

.project-content .links {
  display: flex;
  flex-direction: column;
}

.portfolio-container section > div.image-wrapper {
  width: min(600px, 100vw);
  height: 400px;
  position: relative;
  max-height: 90vh;
  margin: 20px;
  background: var(--white);
  overflow: hidden;
  border-radius: 10px;
}

.portfolio-container section > div.image-wrapper.vertical {
  width: min(400px, 100vw);
  height: 800px;
}

.portfolio-container section > h2 {
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  font-size: 22px;
  color: beige;
  font-weight: bold;
}

.portfolio-container img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.portfolio-container .swiper {
  width: 100%;
  height: 100%;
}

.portfolio-container .swiper-vertical .swiper-button-next,
.portfolio-container .swiper-vertical .swiper-button-prev {
  left: 50%;
  transform: rotate(90deg);
  transform-origin: left center;
}

.portfolio-container .swiper-vertical .swiper-button-prev {
  top: 10px;
}

.portfolio-container .swiper-vertical .swiper-button-next {
  top: auto;
  bottom: 50px;
}

.portfolio-container .swiper-slide p {
  display: none;
}

.swiper-horizontal > .swiper-pagination-progressbar,
.swiper-pagination-progressbar.swiper-pagination-horizontal,
.swiper-pagination-progressbar.swiper-pagination-vertical.swiper-pagination-progressbar-opposite,
.swiper-vertical
  > .swiper-pagination-progressbar.swiper-pagination-progressbar-opposite {
  width: 50% !important;
  left: 25% !important;
  top: unset !important;
  bottom: 0 !important;
}

.portfolio-container {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='199' viewBox='0 0 100 199'%3E%3Cg fill='%239C92AC' fill-opacity='0.9'%3E%3Cpath d='M0 199V0h1v1.99L100 199h-1.12L1 4.22V199H0zM100 2h-.12l-1-2H100v2z'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
}

.home-container {
  background-color: #531430 ;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='199' viewBox='0 0 100 199'%3E%3Cg fill='%239C92AC' fill-opacity='0.4'%3E%3Cpath d='M0 199V0h1v1.99L100 199h-1.12L1 4.22V199H0zM100 2h-.12l-1-2H100v2z'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
}

.profile-container {
  background-color: #f2b16a;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='199' viewBox='0 0 100 199'%3E%3Cg fill='%239C92AC' fill-opacity='0.4'%3E%3Cpath d='M0 199V0h1v1.99L100 199h-1.12L1 4.22V199H0zM100 2h-.12l-1-2H100v2z'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
}

.swiper-button-prev {
  display: none !important;
}

.swiper-button-next,
.swiper-button-prev {
  color: black !important;
  padding: 10px 21px;
  border-radius: 40px;
  border: solid 2px black;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 23px !important;
  font-weight: 700 !important;
}

.swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  background: black !important;
}

/* Home */
.home-card {
  width: 100%;
  height: calc(100% - 100px);
  margin-left: 20px;
  margin-top: 50px;
  margin-right: 20px;

  border-radius: 20px;

  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-size: cover;
  flex-direction: column;
}

.home-card .banner {
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  width: 85%;
  padding-bottom: 30px;
  text-align: center;
}

.home-card .banner-text {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.home-card .grid-area {
  width: 100%;
}
.home-card .banner-text h1 {
  font: 90px/1.1em "opensans-bold", sans-serif;
  color: blue;
  letter-spacing: -2px;
  margin: -15px auto 0 auto;
  display: inline-flex;
  
}

.home-card .banner-text h1 .italic-headline {
  font-family: "cursive", cursive;
  font-style: italic;
  color: rgb(247, 78, 78);
}

.home-card .banner-text h3 {
  font: 20px/1.9em "opensans-bold", serif;
  color: #ddd;
  margin: 15px auto;
  width: 70%;
  text-align: center;
}

.home-card .banner-text h3 span,
.home-card .banner-text h3 a {
  color: #fff;
}
.home-card .banner-text hr {
  width: 60%;
  margin: 18px auto 24px auto;
  border-color: #2f2d2e;
  border-color: rgba(150, 150, 150, 0.1);
}

.home-card .button-wrapper {
  display: flex;
  margin-top: 20px;
}

.home-card .home-button {
  background: transparent;
  font-family: 'Tilt Prism';
  font-weight: 700;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 20px;
  min-width: 150px;
  border: white solid 1px;
  color: white;
  box-shadow: black 10px 10px;
  text-transform: uppercase;
}

.home-card .home-button:not(:last-child) {
  margin-right: 20px;
}

.home-card .home-button:hover {
  background-color: white;
  color: black;
}

.home-card .outline-text {
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 3px;
  color: #191970;
  font-weight: 700;
}

.home-card svg {
  display: block;
  font: 1.3em "Montserrat";
  width: 400px;
  height: 100px;
  margin: 0 auto;
}

.text-copy {
  fill: none;
  stroke: transparent;
  stroke-dasharray: 10% 10%;
  stroke-width: 4px;
  stroke-dashoffset: 0%;
  animation: stroke-offset 5.5s infinite linear;
}

.text-copy:nth-child(1) {
  stroke: transparent;
  animation-delay: -1;
}

.text-copy:nth-child(2) {
  stroke: transparent;
  animation-delay: -2s;
}

.text-copy:nth-child(3) {
  stroke: transparent;
  animation-delay: -3s;
}

.text-copy:nth-child(4) {
  stroke: #fdb731;
  animation-delay: -4s;
}

.text-copy:nth-child(5) {
  stroke: #fdb731;
  animation-delay: -5s;
}

@keyframes stroke-offset {
  100% {
    stroke-dashoffset: -40%;
  }
}

.home-card .background-text {
  font-family: 'Montserrat';
  font-weight: 700;
  margin-bottom: 0rem;
  background-image: linear-gradient(yellow 0%, orange 100%);
  background-size: auto auto;
  background-clip: border-box;
  background-size: auto 200%;
  color: #fff;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2s linear infinite;
  display: inline-block;
}

@keyframes textclip {
  to {
    background-position: center 200%;
  }
}

.background-city {
  width: 100%;
  height: 300px;
  background: no-repeat center top/100% url("./city-illustrator.png");
  position: absolute;
  bottom: 0;
  z-index: 0;
  pointer-events: none;
}

.author-text {
  font-size: 2.5em;
  font-weight: 700;
  color: #b08683 ;
  margin-top: -2.5em;
  font-family: 'Poppins';
  margin-bottom: 30px;
}

.plane {
  width: 100px;
  height: 40px;
  background: no-repeat center top/100% url("./plane-illustrator.png");
  position: absolute;
  top: 200px;
  animation: flight 10s ease-in infinite;
}

@keyframes flight {
  0% {
    right: 0;
  }

  100% {
    right: 100vw;
  }

}


/* Profile */

.profile-container {
  flex-direction: column;
}

.profile-container .description {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 80px;
  margin-bottom: 30px;
}

.profile-container .description h2 {
  font-size: 40px;
}

.bars {
  flex: 1;
  float: left;
  padding: 0;
  text-align: left;
}
.bars .skills {
  margin-top: 36px;
  list-style: none;
}
.bars li {
  position: relative;
  margin-bottom: 60px;
  background: #333;
  height: 42px;
  border-radius: 0px;
}
.bars li em {
  font: 15px "opensans-bold", sans-serif;
  color: #313131;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: normal;
  position: relative;
  top: -36px;
}
.bar-expand {
  position: absolute;
  left: 0;
  top: 0;

  margin: 0;
  padding-right: 24px;
  background: #313131;
  display: inline-block;
  height: 42px;
  line-height: 42px;
  border-radius: 0px;
}

.education-wrapper {
  display: flex;
  margin-bottom: 50px;
  background-color: beige;
  padding: 50px 20px;
  border-radius: 20px;
  box-shadow: black 10px 10px;
}

.education-wrapper h3.title span {
  border-bottom: 3px solid brown;
  padding-bottom: 6px;
  font-size: 30px;
}

.education-wrapper .content h3 {
  font: 25px/30px "opensans-bold", sans-serif;
}

.education-wrapper .content .info {
  font: 16px/24px "librebaskerville-italic", serif;
  color: brown;
  margin-bottom: 9px;
  margin-top: 9px;
}

.education-wrapper .content .work-description {
  font: 16px/24px "librebaskerville-italic", serif;
  margin-bottom: 15px;
}

@media only screen and (max-width: 1024px) {
  /* Portfolio */
  .portfolio-container section {
    flex-direction: column;
  }

  .portfolio-container section > h2 {
    writing-mode: horizontal-tb;
    transform: none;
    font-size: 22px;
  }

  .home-card .banner-text h1 {
    font: 80px/1.1em "opensans-bold", sans-serif;
    letter-spacing: -1px;
    margin: -20px auto 0 auto;
  }


  
}

@media only screen and (max-width: 900px) {
  .home-card .banner-text h1 {
    font: 78px/1.1em "opensans-bold", sans-serif;
    letter-spacing: -1px;
  }
  .home-card .banner-text h3 {
    font: 17px/1.9em "librebaskerville-regular", serif;
    width: 80%;
  }
  .home-card .banner-text hr {
    width: 65%;
    margin: 12px auto;
  }

  .background-city {
    height: 250px;
  }
}

@media only screen and (max-width: 767px) {
  .home-card .banner-text h1 {
    font: 68px/1.1em "opensans-bold", sans-serif;
  }
  .home-card .banner-text h3 {
    font: 16px/1.9em "librebaskerville-regular", serif;
    width: 85%;
  }
  .home-card .banner-text hr {
    width: 80%;
    margin: 18px auto;
  }


  .home-card svg {
    width: 250px;
    height: 80px;
    font: 1.5em "Montserrat";
  }



  .home-card .home-button {
    border-radius: 20px;
    padding: 10px 15px;
    min-width: 120px;
    font-size: 16px;
  }

  .education-wrapper {
    flex-direction: column;
  }

  .education-wrapper h3.title {
    margin-bottom: 20px;
  }

  .profile-container .description h2 {
    font-size: 35px;
  }

  .bars li em {
    font-size: 13px;
  }

  .education-wrapper .content h3 {
    font: 20px/25px "opensans-bold", sans-serif;
  }

  .background-city {
    height: 160px;
  }

  .author-text {
    font-size: 2em;
  }


}

@media only screen and (max-width: 480px) {
  .home-card .banner {
    padding-top: 24px;
  }
  .home-card .banner-text h1 {
    font: 40px/1.1em "opensans-bold", sans-serif;
    margin: -5px auto 0 auto;
  }
  .home-card .banner-text h3 {
    font: 14px/1.9em "librebaskerville-regular", sans-serif;
    width: 90%;
  }

  .home-card .button-wrapper {
    display: flex;
    flex-direction: column;
  }

  .home-card .home-button {
    border-radius: 20px;
    padding: 10px 15px;
    min-width: 80vw;
    font-size: 14px;
  }

  .home-card .home-button:not(:last-child) {
    margin-bottom: 10px;
    margin-right: unset;
  }

  .profile-container .description h2 {
    font-size: 30px;
  }

  .background-city {
    height: 120px;
  }

  .home-card svg {
    width: 200px;
    height: 50px;
    font: 3em "Montserrat";
  }

  .author-text {
    font-size: 1.5em;
  }

  div.project-content {
    height: 260px;
  }

  div.project-content p,
  div.project-content a
   {
    font-size: 15px;
  }

  .project-content h3 {
    font-size: 25px;
    font-weight: 700;
    letter-spacing: -1px;
    line-height: 1.2;
}

}
